import React from "react";
import { graphql } from "gatsby";

import { CondoMarket, PromotionInvestments } from "page_components/promotion";
import { Hero } from "page_components/Home";

import Layout from "components/Layout";

const Promotion = ({ data }) => {
  const all_promotion_offers = data?.allInvestments?.nodes?.filter(
    (item) => item.investments.promotion
  );
  const ordered_promotion_offers =
    data?.promotionInvestments?.promocje?.promotion?.filter(
      (item) => item.investments.promotion
    );
  const seo = data?.promotionInvestments?.seo;

  const promotion_offers = [
    ...ordered_promotion_offers,
    ...all_promotion_offers.filter(
      (all_item) =>
        !ordered_promotion_offers.some(
          (ordered_item) => ordered_item.slug === all_item.slug
        )
    ),
  ];

  return (
    <Layout
      seo={{ title: seo?.title, desc: seo?.metaDesc }}
      location="Promocje"
    >
      <Hero promotion_mode slider={data.homePage.homePage.slide} />
      <CondoMarket />
      <PromotionInvestments data={promotion_offers} />
    </Layout>
  );
};

export default Promotion;

export const query = graphql`
  query promotionInvestments {
    allInvestments: allWpInvestment(sort: { fields: investments___position }) {
      nodes {
        title
        uri
        slug
        investments {
          shortLocation
          mainTitle
          deadline
          promotion
          priceMax
          promotionTitle
          promotionDescription
          promotionPrice
          promotionRegularPrice
          promotionPriceType
          promotionLowestPrice
          promotionStatus {
            icon {
              sourceUrl
            }
            text
          }
          investLogo {
            sourceUrl
          }
          mainImg {
            sourceUrl
          }
          subtitle
          labelState
          labelText
        }
      }
    }
    promotionInvestments: wpPage(id: { eq: "cG9zdDo1MDMz" }) {
      seo {
        title
        metaDesc
      }
      promocje {
        promotion {
          ... on WpInvestment {
            title
            uri
            slug
            investments {
              shortLocation
              mainTitle
              deadline
              promotion
              priceMax
              promotionTitle
              promotionDescription
              promotionPrice
              promotionRegularPrice
              promotionPriceType
              promotionLowestPrice
              promotionStatus {
                icon {
                  sourceUrl
                }
                text
              }
              investLogo {
                sourceUrl
              }
              mainImg {
                sourceUrl
              }
              subtitle
              labelState
              labelText
            }
          }
        }
      }
    }
    homePage: wpPage(id: { eq: "cG9zdDo1MDMz" }) {
      homePage {
        slide {
          slideImage {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`;
