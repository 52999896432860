import "./styles.scss";

import React, { useState } from "react";
import classnames from "classnames";
import { Link } from "gatsby";

import Button from "../Button";
import InvestmentLogo from "../InvestmentLogo";

import { useApp } from "context/app";

import priceFormat from "utils/priceFormat";

import CPinIcon from "icons/facilities/c-pin.svg";
import MessageIcon from "icons/message.svg";

const mainClass = "promotion-investment-item";

const PromotionInvestmentItem = ({
  href,
  logo,
  name,
  image,
  location,
  regular_price,
  currency,
  promotion_title,
  promotion_description,
  promotion_price,
  promotion_price_type,
  promotion_lowest_price,
  promotion_status,
  labelState,
  labelText,
}) => {
  const { setContactFormModal } = useApp();
  const [is_touched, setIsTouched] = useState(false);

  const formatted_regular_price = priceFormat(
    regular_price,
    currency,
    promotion_price_type
  );
  const formatted_promotion_price = priceFormat(
    promotion_price,
    currency,
    promotion_price_type
  );
  const formatted_promotion_lowest_price = priceFormat(
    promotion_lowest_price,
    currency,
    promotion_price_type
  );

  const clickHandler = (e) => {
    if (!!!is_touched) {
      e.preventDefault();
    }
    setIsTouched(true);
  };

  return (
    <Link
      to={href}
      className={mainClass}
      onClick={clickHandler}
      onMouseEnter={clickHandler}
      onMouseLeave={() => setIsTouched(false)}
    >
      <div
        className={`${mainClass}__image`}
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <InvestmentLogo image={logo} small />
        {labelState && (
          <div className={`${mainClass}__image__label`}>
            <span>{labelText}</span>
          </div>
        )}
      </div>
      <div className={`${mainClass}__content`}>
        {!!promotion_price && (
          <div className={`${mainClass}__content__price`}>
            <div
              className={classnames(
                `${mainClass}__content__price__item`,
                `${mainClass}__content__price__item--regular`
              )}
            >
              <label>Cena regularna:</label>
              <strike>{`${formatted_regular_price?.price} ${formatted_regular_price?.suffix}`}</strike>
            </div>
            <div
              className={classnames(
                `${mainClass}__content__price__item`,
                `${mainClass}__content__price__item--promotion`
              )}
            >
              <label>Promocyjna cena apartamentów już od</label>
              <span>
                {formatted_promotion_price?.price}{" "}
                <span>{formatted_promotion_price?.suffix}</span>
              </span>
              {!!promotion_lowest_price && (
                <p>
                  Najniższa cena z ostatnich 30 dni:{" "}
                  <span>
                    <strong>{formatted_promotion_lowest_price?.price}</strong>{" "}
                    {formatted_promotion_lowest_price?.suffix}
                  </span>
                </p>
              )}
            </div>
          </div>
        )}
        <div className={`${mainClass}__content__title`}>
          <span>
            <CPinIcon />
            {location}
          </span>
          <h3>{promotion_title}</h3>
          <label>{name}</label>
        </div>
        <div className={`${mainClass}__content__info`}>
          <p>{promotion_description}</p>
          {!!promotion_status && (
            <div className={`${mainClass}__content__info__status`}>
              <img src={promotion_status?.icon?.sourceUrl} alt="" />
              <span>{promotion_status?.text}</span>
            </div>
          )}
        </div>
        <div className={`${mainClass}__content__actions`}>
          <Button href={href}>Szczegóły...</Button>
          <Button
            onClick={() =>
              setContactFormModal({
                investment: `${name} - ${promotion_title}`,
              })
            }
            icon={<MessageIcon />}
            variant="navy"
          >
            Zapytaj
          </Button>
        </div>
      </div>
    </Link>
  );
};

export default PromotionInvestmentItem;
