import "./styles.scss";
import React from "react";

import MoneyBoxIcon from "icons/money-box.svg";

const mainClass = "promotion__condo-market";

const CondoMarket = () => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h2>
            Skorzystaj z najbardziej
            <br />
            <span>atrakcyjnych warunków</span>
            <br />
            <label>na rynku condo</label>
          </h2>
          <p>
            W condo.pl wybór najlepszej oferty inwestycyjnej na rynku
            apartamentów hotelowych i Second Home to bułka z masłem. W naszym
            serwisie nie tylko prezentujemy wyselekcjonowane projekty, które
            polecamy uwadze inwestorów. U nas znajdziesz także unikalne promocje
            i oferty limitowane, które pozwalają jeszcze korzystniej
            zainwestować kapitał.
          </p>
        </div>
        <div className={`${mainClass}__content`}>
          <MoneyBoxIcon />
          <span>
            <b>Zadbaj o swoje pieniądze</b>
            <br />
            zabezpiecz je przed inflacją i pomnażaj inwestując z condo.pl!
          </span>
        </div>
      </div>
    </div>
  );
};

export default CondoMarket;
